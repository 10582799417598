import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { NextSeo } from 'next-seo'
import { useQuery } from 'react-query'

import { Box } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import AppLayout from '~/components/layouts/AppLayout'
import Text, { Title } from '~/components/shared/Text'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'

import { formatMessage, getEntityByBrand } from '~/brands'

import ExtendedButton from '../components/shared/ExtendedButton'

import bgImageBoat from '~/../public/static/images/background-404.boat.jpg'
import bgImageRv from '~/../public/static/images/background-404.rv.jpg'

const RecentlyAddedSection = dynamic(() =>
  import('~/views/Homepage/RecentlyAddedSection')
)
const NewsReviewsSection = dynamic(() =>
  import('~/views/Homepage/NewsReviewsSection')
)

const bgImage = getEntityByBrand<string>({ rv: bgImageRv, boat: bgImageBoat })

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        color: theme.palette.common.white,
        textAlign: 'center',
        lineHeight: 1.3
      },
      subtitle: {
        color: theme.palette.common.white,
        maxWidth: 550,
        textAlign: 'center'
      },
      btn: {
        whiteSpace: 'nowrap'
      }
    }),
  { name: 'Error404' }
)

export default function Error404() {
  const classes = useStyles()

  const { data } = useQuery('lastListings', () =>
    EmpriseGroup.getLastListings().then((resp) =>
      Deserializer.deserialize(resp)
    )
  )

  return (
    <AppLayout
      disableGutters
      containerProps={{
        disableGutters: true,
        maxWidth: false,
        style: {
          position: 'relative',
          zIndex: 1
        }
      }}
    >
      <NextSeo title="Error 404" />
      <Box position="relative">
        <Image
          src={bgImage}
          placeholder="blur"
          layout="fill"
          objectPosition="bottom"
          objectFit="cover"
        />
        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          alignItems="center"
          flexDirection="column"
          width="100%"
          style={{ padding: '16px' }}
          minHeight="350px"
          height="540px"
          zIndex={2}
        >
          <Title mb={4} mt={0} variant="h1" className={classes.title}>
            {formatMessage('notFound.title')}
          </Title>
          <Text mb={1} className={classes.subtitle}>
            Unfortunately, the page you’re looking for doesn’t exist (anymore)
            or there was an error in the link you followed or typed.
          </Text>
          <Text mb={6} className={classes.subtitle}>
            Have no fear, help is near.
          </Text>
          <Link href="/" passHref>
            <ExtendedButton color="primary" className={classes.btn}>
              Go To homepage
            </ExtendedButton>
          </Link>
        </Box>
      </Box>

      <RecentlyAddedSection data={data} isReduce />

      <NewsReviewsSection />
    </AppLayout>
  )
}
